import clsx from 'clsx';
import React from 'react';
import StepPendingSvg from '../../../assets/svg/step_pending.svg';
import StepSuccessSvg from '../../../assets/svg/step_success.svg';
import StepperDesktopWrapper from './styles';
import { getStepState } from './utils';

export type OnClickStepperDesktopEvent = (stepIndex: number) => void;

export type StepperDesktopProps = {
  /**
   * Current state index
   */
  currentStep?: number;
  /**
   * List of steps
   */
  labels?: string[];
  /**
   * Optional click controller for completed steps
   */
  onClick?: OnClickStepperDesktopEvent;
};

export const defaultProps: StepperDesktopProps = {
  currentStep: 0,
  labels: [
    'Step One',
    'Step Two',
    'Step Three',
    'Step Four',
    'Step Five',
    'Step Six',
    'Step Seven',
    'Step Eight',
    'Step Nine',
    'Step Ten',
  ],
};

const StepperDesktop: React.FC<StepperDesktopProps> = ({
  currentStep,
  labels,
  onClick,
  ...otherProps
}) => {
  const numberOfSteps = labels.length;

  return (
    <StepperDesktopWrapper {...otherProps} data-testid="steps">
      {labels.map((stepLabel: string, stepIndex: number) => {
        const stepNumber = stepIndex + 1;
        const stepStatus = getStepState(stepNumber, currentStep);

        return (
          <div
            key={stepIndex}
            className={clsx('step-wrapper', stepStatus)}
            onClick={() => {
              if (stepStatus === 'completed') {
                onClick(stepIndex);
              }
            }}
          >
            <div className="step-status-indicator">
              <div className="step-status-indicator--wrapper">
                {stepStatus === 'completed' ? (
                  <img src={StepSuccessSvg} alt="StepSuccessSvg" />
                ) : (
                  stepStatus === 'pending' && (
                    <img src={StepPendingSvg} alt="StepPendingSvg" />
                  )
                )}
              </div>
            </div>
            <div className="step-number">{stepNumber}</div>
            <div className="step-label">{stepLabel}</div>
            <div className="progress">
              <div className="progress-bar" />
            </div>
          </div>
        );
      })}
    </StepperDesktopWrapper>
  );
};

StepperDesktop.defaultProps = defaultProps;

export default StepperDesktop;
