import { theme as sodimacTheme } from './sodimac';
import { theme as falabellaTheme } from './falabella';

export const defaultTheme = 'sodimac';
export const themes: { [key: string]: any } = [
  {
    ...sodimacTheme,
    id: 'sodimac',
    name: 'Sodimac',
  },
  {
    ...falabellaTheme,
    id: 'falabella',
    name: 'Falabella',
  },
];

export const themesList: string[] = themes.map((item: any) => item.id);
