import styled from 'styled-components';

export const ListGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const ItemStyled = styled.div`
  box-sizing: border-box;
  padding: 6px;
  color: ${({ theme }) => theme.listGroupTheme.item.state.default.color};
  border-color: ${({ theme }) =>
    theme.listGroupTheme.item.state.default.borderColor};
  border-width: 1px;
  border-style: solid;

  &.active {
    color: ${({ theme }) => theme.listGroupTheme.item.state.active.color};
    border-color: ${({ theme }) =>
      theme.listGroupTheme.item.state.active.borderColor};
    background-color: ${({ theme }) =>
      theme.listGroupTheme.item.state.active.backgroundColor};
  }
`;
