import { STEP_STATUSES } from './constants';

export const getStepState = (stepNumber: number, currentStep: number) => {
  const { COMPLETED, CURRENT, PENDING } = STEP_STATUSES;

  if (stepNumber < currentStep) {
    return COMPLETED;
  } else if (stepNumber === currentStep) {
    return CURRENT;
  } else {
    return PENDING;
  }
};
