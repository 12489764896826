import styled from 'styled-components';

export const SwitchStyled = styled.div`
  position: relative;
  width: ${({ theme }) => theme.switchTheme.switch.width};
  height: ${({ theme }) => theme.switchTheme.switch.height};
`;

export const InputStyled = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

export const LabelStyled = styled.label`
  position: absolute;
  transform: translate(0%, -50%);
  top: 50%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: ${({ theme }) => theme.switchTheme.label.width};
  height: ${({ theme }) => theme.switchTheme.label.height};
  background: ${({ theme }) => theme.switchTheme.state.default.backgroundColor};
  border-radius: 100px;

  transition: background-color 0.2s;

  ${InputStyled}:checked + & {
    background: ${({ theme }) =>
      theme.switchTheme.state.active.backgroundColor};
  }
`;

export const SliderStyled = styled.span`
  top: 50%;
  transform: translate(0, -50%);

  content: '';
  position: absolute;
  left: ${({ theme }) => theme.switchTheme.switch.position};
  width: ${({ theme }) => theme.switchTheme.slider.width};
  height: ${({ theme }) => theme.switchTheme.slider.height};
  border-radius: 24px;
  transition: 0.2s;
  background: ${({ theme }) =>
    theme.switchTheme.state.default.sliderBackgroundColor};
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  ${InputStyled}:checked + ${LabelStyled} & {
    background: ${({ theme }) =>
      theme.switchTheme.state.active.sliderBackgroundColor};
  }

  ${LabelStyled}:active > & {
    width: 30px;
  }

  ${InputStyled}:checked + ${LabelStyled} & {
    left: calc(100% - ${({ theme }) => theme.switchTheme.switch.position});
    transform: translate(-100%, -50%);
  }
`;
