import styled from 'styled-components';

export const HighlightStyled = styled.div`
  &.transactional {
    color: ${({ theme }) => theme.highlightTheme.transactional};
  }

  &.notTransactional {
    color: ${({ theme }) => theme.highlightTheme.notTransactional};
  }
`;
