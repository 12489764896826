import { IButtonTheme } from '../models/IButtonTheme';
import { ICheckTheme } from '../models/ICheckTheme';
import { IHighlightTheme } from '../models/IHighlightTheme';
import { IInputSpinnerTheme } from '../models/IInputSpinnerTheme';
import { IInputTheme } from '../models/IInputTheme';
import { IListGroupTheme } from '../models/IListGroupTheme';
import { IMeatballsTheme } from '../models/IMeatballsTheme';
import { IRadioTheme } from '../models/IRadioTheme';
import { ISpinnerTheme } from '../models/ISpinnerTheme';
import { ISwitchTheme } from '../models/ISwitchTheme';
import { IToggleTheme } from '../models/IToggleTheme';

const colors = {
  transactional: {
    base: '#ff6200',
    decreased50: '#feb07f',
  },
  notTransactional: {
    base: '#495867',
  },
  neutral: {
    transparent: 'transparent',
    red: 'red',
    white: '#ffffff',
    color1: '#f8f8f8',
    color2: '#e1e1e1',
    color3: '#bfbfbf',
  },
} as const;

const button: IButtonTheme = {
  isPull: true,
  borderRadius: {
    normal: '0.188rem',
    pill: '10rem',
  },
  sizes: {
    xs: {
      fontSize: '12px',
      fontWeight: 100,
      lineHeight: '12px',
      padding: '3px 10px',
    },
    sm: {
      fontSize: '17px',
      fontWeight: 700,
      lineHeight: '20px',
      padding: '4px 10px',
    },
    md: {
      fontSize: '14px',
      fontWeight: 100,
      lineHeight: '20px',
      padding: '11px 33px',
    },
  },
  type: {
    primary: {
      variants: {
        transactional: {
          default: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.transactional.base,
            backgroundColor: colors.transactional.base,
          },
          hover: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.transactional.base,
            backgroundColor: colors.transactional.base,
          },
          active: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.transactional.base,
            backgroundColor: colors.transactional.base,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.color2,
            backgroundColor: colors.neutral.color2,
          },
        },
        notTransactional: {
          default: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.notTransactional.base,
          },
          hover: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.notTransactional.base,
          },
          active: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.notTransactional.base,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.color2,
            backgroundColor: colors.neutral.color2,
          },
        },
        ghost: {
          default: {
            textDecoration: 'underline',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
          hover: {
            textDecoration: 'underline',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
          active: {
            textDecoration: 'underline',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
          disabled: {
            textDecoration: 'underline',
            color: colors.neutral.color2,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
        },
      },
    },
    secondary: {
      variants: {
        transactional: {
          default: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.notTransactional.base,
          },
          hover: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.notTransactional.base,
          },
          active: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.notTransactional.base,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.color2,
            backgroundColor: colors.neutral.color2,
          },
        },
        notTransactional: {
          default: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.neutral.white,
          },
          hover: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.neutral.white,
          },
          active: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.neutral.white,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.color2,
            backgroundColor: colors.neutral.color2,
          },
        },
        ghost: {
          default: {
            textDecoration: 'underline',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
          hover: {
            textDecoration: 'underline',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
          active: {
            textDecoration: 'underline',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
          disabled: {
            textDecoration: 'underline',
            color: colors.neutral.color2,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
        },
      },
    },
  },
};

const spinner: ISpinnerTheme = {
  sizes: {
    xs: {
      width: '1rem',
      height: '1rem',
    },
    sm: {
      width: '2rem',
      height: '2rem',
    },
    md: {
      width: '3rem',
      height: '3rem',
    },
    lg: {
      width: '4rem',
      height: '4rem',
    },
  },
  variants: {
    default: {
      color: colors.transactional.base,
    },
    success: {
      color: '#28a745',
    },
    danger: {
      color: '#dc3545',
    },
    warning: {
      color: '#ffc107',
    },
  },
};

const switchTheme: ISwitchTheme = {
  switch: {
    position: '0px',
    width: '52px',
    height: '28px',
  },
  label: {
    width: '100%',
    height: '18px',
  },
  slider: {
    width: '24px',
    height: '24px',
  },
  state: {
    default: {
      backgroundColor: colors.neutral.color3,
      sliderBackgroundColor: colors.neutral.color1,
    },
    active: {
      backgroundColor: colors.transactional.decreased50,
      sliderBackgroundColor: colors.transactional.base,
    },
  },
};

const toggleTheme: IToggleTheme = {
  state: {
    default: {
      borderColor: colors.neutral.color3,
    },
    active: {
      borderColor: colors.transactional.base,
    },
  },
};

const radioTheme: IRadioTheme = {
  state: {
    checked: colors.transactional.base,
    default: {
      color: colors.neutral.color3,
      borderColor: colors.neutral.color3,
      backgroundColor: colors.neutral.white,
    },
  },
};

const meatballsTheme: IMeatballsTheme = {
  backgroundColor: colors.transactional.base,
};

const inputSpinnerTheme: IInputSpinnerTheme = {
  input: {
    color: colors.notTransactional.base,
    borderColor: colors.neutral.transparent,
  },
  button: {
    color: colors.notTransactional.base,
    borderColor: colors.neutral.color2,
    backgroundColor: colors.neutral.color2,
  },
};

const listGroupTheme: IListGroupTheme = {
  item: {
    state: {
      default: {
        color: colors.notTransactional.base,
        borderColor: colors.neutral.color3,
      },
      active: {
        color: colors.neutral.white,
        borderColor: colors.transactional.base,
        backgroundColor: colors.transactional.base,
      },
    },
  },
};

const inputTheme: IInputTheme = {
  padding: {
    top: '5px',
    left: '1px',
    right: '7px',
    bottom: '5px',
  },
  withCleanButton: false,
  borderRadius: '0px',
  border: {
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '1px',
  },
  state: {
    default: {
      color: colors.notTransactional.base,
      borderColor: colors.notTransactional.base,
    },
    error: {
      color: colors.neutral.red,
      borderColor: colors.neutral.red,
    },
  },
};

const checkTheme: ICheckTheme = {
  state: {
    default: {
      backgroundColor: colors.neutral.white,
      borderColor: colors.neutral.color3,
    },
    hover: {
      backgroundColor: colors.neutral.white,
      borderColor: colors.neutral.color3,
    },
    checked: {
      backgroundColor: colors.transactional.base,
      borderColor: colors.transactional.base,
    },
  },
};

const highlightTheme: IHighlightTheme = {
  transactional: colors.transactional.base,
  notTransactional: colors.notTransactional.base,
};

export const theme = {
  breakpoints: {
    xs: 320,
    sm: 720,
    md: 1024,
    lg: 1280,
  },
  spinner,
  button,
  switchTheme,
  toggleTheme,
  radioTheme,
  meatballsTheme,
  inputSpinnerTheme,
  listGroupTheme,
  inputTheme,
  checkTheme,
  highlightTheme,
} as const;
