import styled from 'styled-components';

export const ToggleStyled = styled.div`
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.toggleTheme.state.default.borderColor};
  padding: 6px;
  background-color: #ffffff;
  border-radius: 3px;

  min-height: 4px;
  min-width: 4px;

  &.active {
    border-color: ${({ theme }) => theme.toggleTheme.state.active.borderColor};
  }
`;
