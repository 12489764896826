import clsx from 'clsx';
import React from 'react';
import { BUTTON_SIZES, BUTTON_VARIANTS } from '../../../utils/constants/button';
import { ButtonWrapper } from './styles';

export type OnClickButtonEvent = () => void;

export type ButtonProps = {
  /**
   * Attribute to define if the button is pressed
   */
  active?: boolean;

  /**
   * Attribute to define if the button is disabled
   */
  disabled?: boolean;

  /**
   * Click handler method
   */
  onClick?: OnClickButtonEvent;

  /**
   * Attribute to define the size of the button
   */
  size?: typeof BUTTON_SIZES[number];

  /**
   * Attribute to define variation
   */
  variant?: typeof BUTTON_VARIANTS[number];

  /**
   * Attribute to define if the button is contour type
   */
  outline?: boolean;

  /**
   * Attribute to define if the button is pill type
   */
  pill?: boolean;

  /**
   * Attribute to define if the button should show full width
   */
  fullWidth?: boolean;

  /**
   * Attribute to define CSS styles
   */
  style?: React.CSSProperties;
};

export const defaultProps: ButtonProps = {
  active: false,
  disabled: false,
  outline: false,
  pill: false,
  size: 'md',
  variant: 'notTransactional',
  fullWidth: false,
  style: {},
};

const Button: React.FC<ButtonProps> = ({ active, children, ...otherProps }) => {
  return (
    <ButtonWrapper
      data-testid="button"
      {...otherProps}
      className={clsx({ active })}
    >
      {children}
    </ButtonWrapper>
  );
};

Button.defaultProps = defaultProps;

export default Button;
