import styled from 'styled-components';

export const InputContainerWrapper = styled.div`
  --input-color--default: ${({ theme }) => theme.inputTheme.state.default};
  --input-color--error: ${({ theme }) => theme.inputTheme.state.error};

  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 20px;
  grid-template-rows: 1fr;
  gap: 3px;
  grid-template-areas: 'input button';

  box-sizing: border-box;

  border-style: solid;
  border-color: ${({ theme }) => theme.inputTheme.state.default.borderColor};
  border-top-width: ${({ theme }) => theme.inputTheme.border.top};
  border-left-width: ${({ theme }) => theme.inputTheme.border.left};
  border-right-width: ${({ theme }) => theme.inputTheme.border.right};
  border-bottom-width: ${({ theme }) => theme.inputTheme.border.bottom};

  background-color: #ffffff;

  padding-top: ${({ theme }) => theme.inputTheme.padding.top};
  padding-left: ${({ theme }) => theme.inputTheme.padding.left};
  padding-right: ${({ theme }) => theme.inputTheme.padding.right};
  padding-bottom: ${({ theme }) => theme.inputTheme.padding.bottom};

  width: calc(100% - 22px);
  border-radius: ${({ theme }) => theme.inputTheme.borderRadius};

  &.error {
    border-color: ${({ theme }) => theme.inputTheme.state.error.borderColor};
  }
`;

export const InputWrapper = styled.input`
  grid-area: input;
  outline: none;
  border: none;
  appearance: none;
  padding: 0;
  line-height: 20px;
  width: 100%;

  color: ${({ theme }) => theme.inputTheme.state.default.color};
`;

export const InputButtonClearWrapper = styled.button`
  grid-area: button;
  padding: 0;
  margin: 0;
  width: min-content;
  background-color: transparent;
  border: none;

  cursor: pointer;

  display: ${({ theme }) =>
    theme.inputTheme.withCleanButton ? 'flex' : 'none'};
  align-items: center;
`;

export const InputHelperWrapper = styled.div`
  color: ${({ theme }) => theme.inputTheme.state.default.color};
  font-size: 12px;

  &.error {
    color: ${({ theme }) => theme.inputTheme.state.error.color};
  }
`;
