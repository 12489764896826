import { InputProps } from '..';
import { IRegExp } from '../../../../models/IRegExp';

export const handleClickClear = (onChange: InputProps['onChange']) => {
  if (onChange !== undefined) {
    onChange('');
  }
};

type handleChangeProps = {
  event: React.ChangeEvent<HTMLInputElement>;
  onChange: InputProps['onChange'];
  type: InputProps['type'];
  customRegex?: RegExp;
};
export const handleChange = ({
  event,
  onChange,
  type,
  customRegex = REG_EXP[type],
}: handleChangeProps) => {
  const { value: newValue } = event.target;

  if (customRegex.test(newValue)) {
    if (onChange !== undefined) {
      onChange(newValue);
    }
  }
};

export const REG_EXP: IRegExp = {
  text: /^$|^(\w|\d|\s)+$/,
  extendedText: /^$|^(\w|\d|\s|\:|\-)+$/,
  number: /^$|^\d+$/,
  decimal: /^(\+|\-)?(?=\.\d|\d)?(?:\d+)?(?:\.?\d{0,2})?$/,
};
