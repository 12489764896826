import clsx from 'clsx';
import React from 'react';
import { PopoverStyled, PopoverContainerStyled } from './styles';

export type PopoverProps = {
  /**
   * Attribute to define the alignment of the popover position
   */
  align?: 'left' | 'center' | 'right';

  /**
   * Attribute to define if the popover is visible
   */
  visible?: boolean;

  /**
   * Attribute to define the width of the popover
   */
  size?: number;

  /**
   * Attribute to define background color of the popover
   */
  backgroundColor?: string;

  /**
   * Attribute to define position of the popover
   */
  place?: 'top' | 'bottom';

  /**
   * Attribute to define CSS styles
   */
  style?: React.CSSProperties;
};

export const defaultProps: PopoverProps = {
  align: 'center',
  visible: false,
  size: 100,
  backgroundColor: '#ffffff',
  place: 'top',
  style: {},
};

export const PopoverContainer: React.FC = ({ children }) => (
  <PopoverContainerStyled>{children}</PopoverContainerStyled>
);

const Popover: React.FC<PopoverProps> = ({
  children,
  align,
  place,
  visible,
  ...otherProps
}) => {
  return (
    <PopoverStyled
      data-testid="popover"
      className={clsx(align, place, { visible })}
      {...otherProps}
    >
      {children}
    </PopoverStyled>
  );
};

Popover.defaultProps = defaultProps;

export default Popover;
