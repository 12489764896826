import React from 'react';
import Button from '../../Atoms/Button';
import {
  ButtonCloseModalWrapper,
  ModalBodyWrapper,
  ModalContentWrapper,
  ModalDialogWrapper,
  ModalTitleWrapper,
  ModalWrapper,
} from './styles';
import { ReactComponent as CloseModal } from '../../../assets/svg/close-modal.svg';
import { handleClickCloseModal, handleClickStopPropagate } from './utils';
import { MODAL_SIZES, MODAL_VARIANTS } from '../../../utils/constants/modal';
import { WithChildren } from '../../../models/IGeneral';

export type ModalProps = WithChildren<{
  size?: typeof MODAL_SIZES[number];
  variant?: typeof MODAL_VARIANTS[number];
  title?: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
}>;

export type ModalDialogWrapperProps = {
  size: ModalProps['size'];
};

export const defaultProps: ModalProps = {
  size: 'sm',
  variant: 'default',
  open: false,
};

const Modal = ({
  size,
  open,
  onClose,
  children,
  title,
  variant,
}: ModalProps) => {
  if (!open) {
    return null;
  }

  return (
    <ModalWrapper
      data-testid="modal"
      onClick={() => handleClickCloseModal({ onClose })}
    >
      <ModalDialogWrapper
        data-testid="modal-dialog"
        size={size}
        onClick={handleClickStopPropagate}
      >
        <ModalContentWrapper data-testid="modal-content">
          {title && (
            <ModalTitleWrapper
              data-testid="modal-title"
              className={`${variant}`}
            >
              {title}
            </ModalTitleWrapper>
          )}
          {onClose && (
            <ButtonCloseModalWrapper>
              <Button
                onClick={() => handleClickCloseModal({ onClose })}
                variant="ghost"
                data-testid="close-modal"
                style={{ padding: 0 }}
              >
                <CloseModal />
              </Button>
            </ButtonCloseModalWrapper>
          )}
          <ModalBodyWrapper data-testid="modal-body">
            {children}
          </ModalBodyWrapper>
        </ModalContentWrapper>
      </ModalDialogWrapper>
    </ModalWrapper>
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
