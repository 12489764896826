import React from 'react';
import {
  ProgressBar,
  Step,
  StepLine,
  StepLineWrapper,
  StepTitle,
  StepWrapper,
  StepperWrapper,
} from './styles';
import { getStepState } from './utils';

export type OnClickStepper = (stepIndex: number) => void;

export type StepperProps = {
  /**
   * Current state index
   */
  currentStep?: number;
  /**
   * List of steps
   */
  titles?: string[];
  /**
   * Optional click controller for completed steps
   */
  onClick?: OnClickStepper;
};

export const defaultProps: StepperProps = {
  currentStep: 0,
  titles: ['Step One', 'Step Two', 'Step Three', 'Step Four'],
};

const Stepper: React.FC<StepperProps> = ({
  currentStep,
  titles,
  onClick,
  ...otherProps
}) => {
  if (!titles || titles.length === 0) return null;

  const numberOfSteps = titles.length;
  const getStepLine = (isLastStep: boolean, stepStatus: string) => {
    return (
      <>
        {isLastStep ? null : (
          <ProgressBar>
            <StepLineWrapper className={stepStatus}>
              <StepLine className={stepStatus}></StepLine>
            </StepLineWrapper>
          </ProgressBar>
        )}
      </>
    );
  };
  const getStep = (title: string, index: number) => {
    const isLastStep = index === numberOfSteps - 1;
    const stepStatus = getStepState(index, currentStep);

    const handleClick = () => {
      if (onClick) {
        onClick(index);
      }
    };

    return (
      <StepWrapper data-testid={'step-' + index} key={title}>
        <Step className={stepStatus} onClick={handleClick}>
          {index + 1}
        </Step>
        <StepTitle onClick={handleClick} className={stepStatus}>
          {title}
        </StepTitle>
        {getStepLine(isLastStep, stepStatus)}
      </StepWrapper>
    );
  };

  return (
    <StepperWrapper {...otherProps} data-testid="stepper">
      {titles.map((title, index) => {
        return getStep(title, index);
      })}
    </StepperWrapper>
  );
};

Stepper.defaultProps = defaultProps;

export default Stepper;
