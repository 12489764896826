import styled from 'styled-components';

export const MeatballsStyled = styled.div`
  width: 25px;
  height: 25px;

  & svg {
    fill: ${({ theme }) => theme.meatballsTheme.backgroundColor};
  }
`;
