import clsx from 'clsx';
import React, { FC } from 'react';
import { ItemStyled, ListGroupStyled } from './styles';

type ItemProps = {
  active?: boolean;
};

const Item: FC<ItemProps> = ({ children, active = false }) => {
  return <ItemStyled className={clsx({ active })}>{children}</ItemStyled>;
};

const ListGroup: FC = ({ children }) => {
  return <ListGroupStyled>{children}</ListGroupStyled>;
};

export default Object.assign(ListGroup, {
  Item,
});
