import { IButtonTheme } from '../models/IButtonTheme';
import { ICheckTheme } from '../models/ICheckTheme';
import { IHighlightTheme } from '../models/IHighlightTheme';
import { IInputSpinnerTheme } from '../models/IInputSpinnerTheme';
import { IInputTheme } from '../models/IInputTheme';
import { IListGroupTheme } from '../models/IListGroupTheme';
import { IMeatballsTheme } from '../models/IMeatballsTheme';
import { IRadioTheme } from '../models/IRadioTheme';
import { ISpinnerTheme } from '../models/ISpinnerTheme';
import { ISwitchTheme } from '../models/ISwitchTheme';
import { IToggleTheme } from '../models/IToggleTheme';

const colors = {
  transactional: {
    base: '#dd0021',
    shaded: '#ac0111',
    dark: '#770012',
  },
  notTransactional: {
    base: '#0072ce',
    shaded: '#044cc3',
    dark: '#004175',
  },
  neutral: {
    transparent: 'transparent',
    gray: '#333333',
    grayCold: '#595959',
    grayMedium: '#8c8c8c',
    grayWarm: '#bfbfbf',
    grayLight: '#f5f5f5',
    white: '#ffffff',
    red: 'red',
    black: '#000000',
  },
} as const;

const button: IButtonTheme = {
  isPull: false,
  borderRadius: {
    normal: '0.188rem',
    pill: '10rem',
  },
  sizes: {
    xs: {
      fontSize: '12px',
      fontWeight: 100,
      lineHeight: '12px',
      padding: '3px 10px',
    },
    sm: {
      fontSize: '17px',
      fontWeight: 700,
      lineHeight: '20px',
      padding: '4px 10px',
    },
    md: {
      fontSize: '14px',
      fontWeight: 100,
      lineHeight: '20px',
      padding: '11px 33px',
    },
  },
  type: {
    primary: {
      variants: {
        transactional: {
          default: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.transactional.base,
            backgroundColor: colors.transactional.base,
          },
          hover: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.transactional.shaded,
            backgroundColor: colors.transactional.shaded,
          },
          active: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.transactional.dark,
            backgroundColor: colors.transactional.dark,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.neutral.grayMedium,
            borderColor: colors.neutral.grayLight,
            backgroundColor: colors.neutral.grayLight,
          },
        },
        notTransactional: {
          default: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.notTransactional.base,
          },
          hover: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.shaded,
            backgroundColor: colors.notTransactional.shaded,
          },
          active: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.dark,
            backgroundColor: colors.notTransactional.dark,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.neutral.grayMedium,
            borderColor: colors.neutral.grayLight,
            backgroundColor: colors.neutral.grayLight,
          },
        },
        ghost: {
          default: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
          hover: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.grayLight,
            backgroundColor: colors.neutral.grayLight,
          },
          active: {
            textDecoration: 'none',
            color: colors.notTransactional.shaded,
            borderColor: colors.neutral.grayLight,
            backgroundColor: colors.neutral.grayLight,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.neutral.grayMedium,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
        },
      },
    },
    secondary: {
      variants: {
        transactional: {
          default: {
            textDecoration: 'none',
            color: colors.transactional.base,
            borderColor: colors.transactional.base,
            backgroundColor: colors.neutral.white,
          },
          hover: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.transactional.base,
            backgroundColor: colors.transactional.base,
          },
          active: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.transactional.dark,
            backgroundColor: colors.transactional.dark,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.neutral.grayMedium,
            borderColor: colors.neutral.grayLight,
            backgroundColor: colors.neutral.grayLight,
          },
        },
        notTransactional: {
          default: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.neutral.white,
          },
          hover: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.base,
            backgroundColor: colors.notTransactional.base,
          },
          active: {
            textDecoration: 'none',
            color: colors.neutral.white,
            borderColor: colors.notTransactional.dark,
            backgroundColor: colors.notTransactional.dark,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.neutral.grayMedium,
            borderColor: colors.neutral.grayLight,
            backgroundColor: colors.neutral.grayLight,
          },
        },
        ghost: {
          default: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
          hover: {
            textDecoration: 'none',
            color: colors.notTransactional.base,
            borderColor: colors.neutral.grayLight,
            backgroundColor: colors.neutral.grayLight,
          },
          active: {
            textDecoration: 'none',
            color: colors.notTransactional.shaded,
            borderColor: colors.neutral.grayLight,
            backgroundColor: colors.neutral.grayLight,
          },
          disabled: {
            textDecoration: 'none',
            color: colors.neutral.grayMedium,
            borderColor: colors.neutral.transparent,
            backgroundColor: colors.neutral.transparent,
          },
        },
      },
    },
  },
};

const spinner: ISpinnerTheme = {
  sizes: {
    xs: {
      width: '1rem',
      height: '1rem',
    },
    sm: {
      width: '2rem',
      height: '2rem',
    },
    md: {
      width: '3rem',
      height: '3rem',
    },
    lg: {
      width: '4rem',
      height: '4rem',
    },
  },
  variants: {
    default: {
      color: colors.notTransactional.base,
    },
    success: {
      color: '#28a745',
    },
    danger: {
      color: '#dc3545',
    },
    warning: {
      color: '#ffc107',
    },
  },
};

const switchTheme: ISwitchTheme = {
  switch: {
    position: '2px',
    width: '52px',
    height: '28px',
  },
  label: {
    width: '100%',
    height: '28px',
  },
  slider: {
    width: '24px',
    height: '24px',
  },
  state: {
    default: {
      backgroundColor: colors.neutral.grayWarm,
      sliderBackgroundColor: colors.neutral.white,
    },
    active: {
      backgroundColor: colors.notTransactional.base,
      sliderBackgroundColor: colors.neutral.white,
    },
  },
};

const radioTheme: IRadioTheme = {
  state: {
    checked: colors.notTransactional.base,
    default: {
      color: colors.neutral.grayMedium,
      borderColor: colors.neutral.grayMedium,
      backgroundColor: colors.neutral.white,
    },
  },
};

const toggleTheme: IToggleTheme = {
  state: {
    default: {
      borderColor: colors.neutral.grayWarm,
    },
    active: {
      borderColor: colors.notTransactional.base,
    },
  },
};

const meatballsTheme: IMeatballsTheme = {
  backgroundColor: colors.notTransactional.base,
};

const inputSpinnerTheme: IInputSpinnerTheme = {
  input: {
    color: colors.neutral.black,
    borderColor: colors.neutral.grayWarm,
  },
  button: {
    color: colors.neutral.black,
    borderColor: colors.neutral.grayWarm,
    backgroundColor: colors.neutral.white,
  },
};

const listGroupTheme: IListGroupTheme = {
  item: {
    state: {
      default: {
        color: colors.neutral.gray,
        borderColor: colors.neutral.grayWarm,
      },
      active: {
        color: colors.neutral.white,
        borderColor: colors.notTransactional.base,
        backgroundColor: colors.notTransactional.base,
      },
    },
  },
};

const inputTheme: IInputTheme = {
  padding: {
    top: '10px',
    left: '15px',
    right: '7px',
    bottom: '10px',
  },
  withCleanButton: true,
  borderRadius: '3px',
  border: {
    top: '1px',
    left: '1px',
    right: '1px',
    bottom: '1px',
  },
  state: {
    default: {
      color: colors.neutral.gray,
      borderColor: colors.neutral.grayWarm,
    },
    error: {
      color: colors.neutral.red,
      borderColor: colors.neutral.red,
    },
  },
};

const checkTheme: ICheckTheme = {
  state: {
    default: {
      backgroundColor: colors.neutral.white,
      borderColor: colors.neutral.grayMedium,
    },
    hover: {
      backgroundColor: colors.neutral.white,
      borderColor: colors.neutral.grayMedium,
    },
    checked: {
      backgroundColor: colors.notTransactional.base,
      borderColor: colors.notTransactional.base,
    },
  },
};

const highlightTheme: IHighlightTheme = {
  transactional: colors.transactional.base,
  notTransactional: colors.notTransactional.base,
};

export const theme = {
  breakpoints: {
    xs: 320,
    sm: 720,
    md: 1024,
    lg: 1280,
  },
  spinner,
  button,
  switchTheme,
  toggleTheme,
  radioTheme,
  meatballsTheme,
  inputSpinnerTheme,
  listGroupTheme,
  inputTheme,
  checkTheme,
  highlightTheme,
} as const;
