import styled from 'styled-components';
import { mediaQuery } from '../../../../utils/mediaQuery';

const primaryColor = '#0072ce';
const secondaryColor = '#acacac';

export const StepperWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  align-items: center;
`;

export const Step = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid ${secondaryColor};
  border-radius: 50%;
  background-color: ${secondaryColor};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  flex-shrink: 0;
  z-index: 1;
  pointer-events: none;

  &.current {
    background-color: white;
    border-color: ${primaryColor};
    color: ${primaryColor};
  }

  &.completed {
    background-color: ${primaryColor};
    border-color: ${primaryColor};
    color: white;
    pointer-events: all;
    cursor: pointer;
  }

  ${mediaQuery.tabletToUp(`
    width: 23px;
    height: 23px;
    font-size: 16px;
    line-height: 23px;
  `)}

  ${mediaQuery.desktopToUp(`
    width: 26px;
    height: 26px;
    font-size: 16px;
    line-height: 26px;
  `)}
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 12px;
  left: 50%;
  right: -50%;

  ${mediaQuery.tabletToUp(`
    top: 13.5px;
  `)}

  ${mediaQuery.desktopToUp(`
    top: 15px;
  `)}
`;

export const StepLineWrapper = styled.div`
  position: relative;
  height: 2px;
  flex-grow: 1;
  background-color: ${secondaryColor};
  min-width: 10px;

  &.completed {
    background-color: ${primaryColor};
  }
`;

export const StepLine = styled.div`
  height: 2px;
  background-color: ${primaryColor};
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;

  &.current {
    display: block;
  }
`;

export const StepTitle = styled.div`
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${secondaryColor};
  margin-top: 2.5px;
  padding: 0 4px;
  text-align: center;
  pointer-events: none;

  &.completed,
  &.current {
    color: ${primaryColor};
  }

  &.completed {
    cursor: pointer;
    pointer-events: all;
  }

  ${mediaQuery.tabletToUp(`
    font-size: 14px;
  `)}

  ${mediaQuery.desktopToUp(`
    font-size: 16px;
  `)}
`;
