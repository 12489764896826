import styled from 'styled-components';

export const CheckStyled = styled.label`
  display: block;
  position: relative;
  padding-left: 28px;
  font-size: 14px;
  padding-top: 1px;
  user-select: none;
`;

export const CheckBoxInputStyled = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const CheckMarkStyled = styled.span`
  width: 20px;
  height: 20px;
  position: absolute;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  top: 0;
  left: 0;
  background-color: ${({ theme }) =>
    theme.checkTheme.state.default.backgroundColor};
  border-color: ${({ theme }) => theme.checkTheme.state.default.borderColor};

  ${CheckStyled}:hover ${CheckBoxInputStyled} ~ & {
    background-color: ${({ theme }) =>
      theme.checkTheme.state.hover.backgroundColor};
    border-color: ${({ theme }) => theme.checkTheme.state.hover.borderColor};
  }

  ${CheckStyled} ${CheckBoxInputStyled}:checked ~ & {
    background-color: ${({ theme }) =>
      theme.checkTheme.state.checked.backgroundColor};
    border-color: ${({ theme }) => theme.checkTheme.state.checked.borderColor};
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  ${CheckStyled} ${CheckBoxInputStyled}:checked ~ &:after {
    display: block;
  }

  ${CheckStyled} &:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
